$(document).ready(function () {
	let allVideos		= document.querySelectorAll(".video");
	let allVideoIframes = document.querySelectorAll(".video__iframe");

	allVideos.forEach((video, index) => video.addEventListener("click", (event) => {
		if (!video.classList.contains('clicked')){
			let videoLink = video.getAttribute('data-video-link');
			let videoId = videoLink.split("v=")[1].substring(0, 11)
			let videoEmbedSrc = "//www.youtube.com/embed/" + videoId;

			allVideoIframes[index].src = videoEmbedSrc + "?autoplay=1";
		}

		video.classList.add('clicked');
	}));

})